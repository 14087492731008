<template>
    <div :class="`${node.type === 'endpoint' ? 'custom' : 'p-1'}`">
        <div :class="`${node.type == 'group' ? 'node-hover' : ''}`">
            <div :class="`${node.type !== 'endpoint' ? 'ml-1' : 1}`">
                <div class="d-flex justify-content-between">
                    <div class="node" @click="expanded = !expanded">
                        <span v-if="hasChildren">{{ expanded ? '&#9660' : '&#9658' }}</span>
                        <span v-if="node.type == 'group'">{{ node.name }}</span>
                    </div>
                </div>
                <div
                    class="d-flex cursor-initial justify-content-between rounded-8 p-1 mb-1"
                    :class="`${node.service_general_status === 'occupied' ? 'bg-black-light' : 'border-black-light'}`"
                    v-if="node.type == 'endpoint'"
                >
                    <p class="mb-0">{{ node.name }}</p>
                </div>
            </div>
        </div>

        <hr class="mb-1 mt-0" v-if="type == 'group'">
        
        <template v-if="expanded">
          <TreeBrowser
              v-for="item in node.child"
              :key="item.name"
              :parent="node"
              :depth="depth + 1"
              :node="item"
          />
        </template>
    </div>
  </template>

<script setup>
import {computed, ref} from "vue";
import TreeBrowser from '@/components/molecule/company/hospital/service-resources/ServiceResourceStatusTree'

const expanded = ref(true)

const props = defineProps({
  node: Object,
  depth: {
    type: Number,
    default: 0
  },
  parent: {
    default: []
  }
})

const hasChildren = computed(() => props.node.child.length)

</script>

<style scoped>
.endpoint{
    padding: 6px;
    margin: 5px;
}
.g-l-2{
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 10px;
}
.bg-black-light {
    background: #00000024;
}
.node {
    cursor: pointer;
}
.node-hover {
    padding: 10px 2px;
}
.node-hover:hover {
    background: #fffeec;
}
.border-black-light {
    border: 1px solid #00000024;
}
.custom{
    position: relative;
    display: inline-block;
    margin-left: 20px;
}
</style>