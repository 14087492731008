<template>
    <div class="card">
        <div style="background: #DBE7F2" class="p-2">
            <TitleButton
                btnTitle="Add New"
                :showBtn="true"
                :showSettingBtn="false"
                :title="'Service Resource Status'"
                @onClickCloseButton="closeButton"
            />
        </div>
        <TreeBrowser
            :style="{opacity: chartLoading ? .5 : 1}"
            v-for="(item, i) in serviceResourceList"
            :key="i"
            :node="item"
        />
    </div>
    <Loader v-if="loading"/>
</template>

<script setup>
    import {computed, inject, onMounted, ref} from "vue";
    import TitleButton from '@/components/atom/TitleButton.vue';
    import TreeBrowser from '@/components/molecule/company/hospital/service-resources/ServiceResourceStatusTree'
    import handleHospital from '@/services/modules/hospital'
    import {useRoute, useRouter} from "vue-router";
    import Loader from '@/components/atom/LoaderComponent'
    

    const { fetchServiceResources } = handleHospital()
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');
    const router = useRouter()
    const route = useRoute()

    const companyId = computed(() => route.params.companyId);

    const serviceResourceList = ref([])
    const loading = ref(false)

    onMounted( async () => {
        await getServiceResourceList();
        console.log(serviceResourceList.value)
    })

    const closeButton = () => {
        router.back();
    }

    const getServiceResourceList = async () => {
    const query = `?company_id=${companyId.value}&resource_type=places`
    try {
        loading.value = true
        let res = await fetchServiceResources(query)
        if (!res.status) {
          return serviceResourceList.value = []
      }
        serviceResourceList.value = res.data
    } catch (err) {
        if (!err.response) {
          showError('Something is wrong. Check your connectivity!!')
        }
        if (err.response) {
          showError(err.response.message)
        }
    } finally {
        loading.value = false
    }
}
</script>